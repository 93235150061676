<script>
import SideBar from '../components/SideBar.vue'
import routes from '../router/index';


export default {
    name: 'HeaderMain',
    components: {
        SideBar
    },
    created() {
        this.darkMode();
        this.setName();
        this.loginVerification();
    },
    data() {
        return {
            firstName: ''
        }
    },
    methods: {
        setName() {
            this.firstName = localStorage.getItem('firstName')
        },
        loginVerification() {
            if (localStorage.getItem("access_token") === null) {
                routes.push('/login');
            }
        },
        openConfigModal() {
            if (!this.showConfigModal) {
                this.showConfigModal = true;
                $('.configModal').show(200)
            } else {
                this.showConfigModal = false;
                $('.configModal').hide(200)
            }
        },
        logout() {
            localStorage.removeItem('access_token')
            routes.push('/login');
        },
        asideOpen() {
            $('body').toggleClass('asideOpen')
            
            if ($('body').hasClass('asideOpen')) {
                localStorage.setItem('asideActive', true)
            } else {
                localStorage.setItem('asideActive', false)
            }
        },
        
        darkModeButton() {
            $('body').toggleClass('dark-mode')
            
            if ($('body').hasClass('dark-mode')) {
                localStorage.setItem('darkMode', true)
            } else {
                localStorage.setItem('darkMode', false)
            }
        },

        darkMode() {
            const darkActive = localStorage.getItem('darkMode')

            if (darkActive == 'true') {
                $('body').addClass('dark-mode')
            }

            var $window = $(window),
            $body = $('body');

            function resize() {
                if ($window.width() < 1051) {
                    return $body.removeClass('asideOpen');
                }

                $body.addClass('asideOpen');
            }

            $window
                .resize(resize)
                .trigger('resize');
        },
       
    }
}
</script>

<template>
    <div>
        <SideBar/>
        <header>
            <div v-on:click="asideOpen" class="hamburguerButton">
                <span></span>
                <span></span>
                <span></span>
            </div>

            <router-link to="/" class="logo">
                <img src="../assets/logoPepper/pepperzap-svg-default.png" alt="logo">
            </router-link>

            <div class="wrapper-content">
                <div class="switchButton">
                    <input type="checkbox" id="darkmode">
                    <label v-on:click="darkModeButton" for="darkmode"></label>
                </div>
    
                <p>Olá, {{ firstName }}!</p>
                
                <div v-on:click="openConfigModal" class="containerArrowIcon">
                    <div class="profileIcon">
                        <!-- <img src="" alt="Foto do perfil"> -->
                    </div>

                    <div v-show="false" class="configModal">
                        <!-- <router-link to="/configurações" class="config">Configurações</router-link> -->
                        <a v-on:click="logout" class="leave">Sair</a>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>




<style scoped>

    body {
        margin: 0;
        font-family: var(--bs-body-font-family);
        font-size: var(--bs-body-font-size);
        font-weight: var(--bs-body-font-weight);
        line-height: var(--bs-body-line-height);
        color: var(--bs-body-color);
        text-align: var(--bs-body-text-align);
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        letter-spacing: var(--body-letter-spacing);
    }

    header {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 20;
        width: var(--largeheader);
        background-color: var(--white);
        padding: 1rem 1rem 1rem 1.5rem;
        border-bottom: 1px solid var(--dark-grey);
        box-shadow: 0 0.275rem 1.25rem rgb(19 16 34 / 5%), 0 0.25rem 0.5625rem rgb(19 16 34 / 3%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: 0.4s ease;

    }

    body.asideOpen header {
        width: var(--largewithAside);
    }
    header .logo {
        display: none;
        margin: auto;
    }


    header .wrapper-content {
        display: flex;
        align-items: center;
    }
    header .hamburguerButton {
        height: 22px;
        width: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        cursor: pointer;
    }
    header .hamburguerButton:hover {
        opacity: 40%;
      }


    header .hamburguerButton span {
        display: block;
        height: 2px;
        width: 100%;
        background-color: #555;
        margin-top: 4px;
    }
    header .hamburguerButton span:first-child {
        margin: 0px;
     }

     header .configModal {
        position: absolute;
        z-index: 1000;
        display: none;
        min-width: 12rem;
        padding: 0.5rem 0px;
        margin: 0;
        font-size: 0.875rem;
        color: #585c7b;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
        border: 1px solid #fff;
        border-radius: 0.5rem;
        box-shadow: 0 0.275rem 1.25rem rgb(19 16 34 / 5%), 0 0.25rem 0.5625rem rgb(19 16 34 / 3%);
        position: absolute;
        top: 40px;
        left: -130px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
     }

     body.dark-mode header .configModal {
        border-color: rgba(255, 255, 255, 0.15);
        color: rgba(255, 255, 255, 0.85);
        background: linear-gradient( 0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03) ), #131022;
     }
     
     .configModal a {
        display: block;
        width: 100%;
        clear: both;
        font-weight: 500;
        color: #3e4265;
        text-align: inherit;
        text-decoration: none;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        font-size: 0.875rem;
        transition: color 0.2s ease-in-out;
        font-family: var(--bs-body-font-family);
        padding: 0.375rem 1rem;
     }

     body.dark-mode .configModal a {
        color: rgba(255, 255, 255, 0.85);
     }

     .configModal a:hover {
        color: #6366f1;
     }
     .configModal a.config {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0px 5px 15px 0px;
     }

     body.dark-mode .configModal a:hover {
        color: #6366f1;
        background-color: #27243d;
     }
     .configModal a.leave {
        position: relative;
        display: flex;
        align-items: center;
     }
     
     .configModal a.config::before,
     .configModal a.leave::before {
        content: '';
        background-image: url('../assets/icon-configuracoes.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 14px;
        height: 20px;
        display: inline-block;
        margin-right: 10px;
     }
     
     .configModal a.leave::before {
        background-image: url('../assets/noun-log-out.svg');
        width: 13px;
        height: 20px;
        background-position: 0px 3px;
     }
     .configModal a.config:hover::before {
        background-image: url('../assets/icon-configuracoes-blue.svg');
     }

     .configModal a.leave:hover::before {
        background-image: url('../assets/noun-log-out-blue.svg');

     }
     

     .switchButton {
        position: relative;
        margin-right: 64px;
     }
     .switchButton::after,
     .switchButton::before {
        content: '';
        background-image: url('../assets/sun.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 20px;
        height: 20px;
        position: absolute;
        left: -30px;
        top: 3px;
     }

     .switchButton::after {
        background-image: url('../assets/moon.svg');
        left: auto;
        right: -30px;
        width: 18px;
        height: 18px;
        top: 4px;
     }

    .switchButton input[type=checkbox]{
        height: 0;
        width: 0;
        visibility: hidden;
        margin: 0px;
        position: absolute;
    }

    .switchButton label {
        cursor: pointer;
        text-indent: -9999px;
        width: 45px;
        height: 25px;
        background-color: var(--converzap-blue);
        display: block;
        border-radius: 100px;
        position: relative;
        box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(99 102 241 / 90%);
}

    .switchButton label:after {
        content: '';
        position: absolute;
        left: 1px;
        top: 1px;
        width: 25px;
        height: 23px;
        transition: 0.5s;
        background-image: url('../assets/circle-white.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    body.dark-mode .switchButton input + label:after {
        background-image: url('../assets/meia-lua.svg');
        left: calc(100% - 2px);
        transform: translateX(-100%);
        width: 19px;
    }

    header .wrapper-content p {
        font-weight: 700;
        margin-right: 1rem;
    }

    .containerArrowIcon {
        position: relative;
        width: 60px;
        cursor: pointer;
    }
    .containerArrowIcon::after {
        content: '';
        background-image: url('../assets/keyboard-arrow-down_119013.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 13px;
        height: 8px;
        position: absolute;
        top: 14px;
        right: 0px;
        cursor: pointer
    }

    header .wrapper-content .profileIcon {
        width: 36px;
        height: 36px;
        overflow: hidden;
        background-color: #555;
        border-radius: 100%;
        background-image: url('../assets/blank-profile-picture.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }


    header .wrapper-content .profileIcon img {
        object-fit: cover;
        object-position: center;
        object-position: center;
        width: 100%;
        height: 100%;
    }

@media screen and (max-width: 1200px) {
 
}

@media screen and (max-width: 995px) {
    header {
        width: var(--largeheader) !important;
        transition: 0s !important;
    }

    header .logo {
        max-width: 35%;
        display: block;
        padding: 0px 10px;
    }
    header .logo img {
        max-width: 200px;
        width: 100%;
        height: auto;
        vertical-align: middle;
    }
}

@media screen and (max-width: 745px) {
    header .wrapper-content p,
    .containerArrowIcon {
        display: none;
    }

    .switchButton {
        margin-right: 30px;
    }
    header .logo {
        max-width: 45%;
    }

}

@media screen and (max-width: 495px) {
    .switchButton::after, 
    .switchButton::before {
        display: none;
    }

    .switchButton {
        margin: 0px;
    }
}


</style>